import React, { useState } from 'react';

import Message from "./components/Message";
import MessageInput from "./components/MessageInput";

import './App.css';

function App() {
  const [messages, setMessages] = useState([]);

  const onMessageSend = (messageText) => {
    const message = {
      sent: true,
      message: messageText
    };

    const reply = {
      sent: false,
      message: "hmm"
    }

    setMessages([...messages, message, reply]);
  }

  return (
    <div className="App">
      {
        messages.map((e, index) => {
          return (
            <Message key={index} sent={e.sent} message={e.message} />
          )
        })
      }

      <MessageInput onMessageSend={onMessageSend} />
    </div>
  );
}

export default App;
