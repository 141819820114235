import React, { useState } from 'react';

const MessageInput = ({
  onMessageSend
}) => {

  const [text, setText] = useState("");

  const sendClicked = (ev) => {
    ev.preventDefault();

    onMessageSend(text);

    setText("");
  };

  const inputChanged = (ev) => {
    setText(ev.target.value)
  };

  return (
    <form onSubmit={sendClicked}>
      <input value={text} onChange={inputChanged} placeholder="Type here..." className="msg-input-field" />
    </form>
  );
}

export default MessageInput
