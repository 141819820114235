import React from 'react';

const Message = ({
  sent,
  message
}) => (
  <div className="message-container">
    <span className={sent ? "message-right" : "message-left"}>
    {message}
    </span>
  </div>
);

export default Message;
